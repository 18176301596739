// extracted by mini-css-extract-plugin
export var contentBox = "highlighted-content-block-module--contentBox--8aed7";
export var cta = "highlighted-content-block-module--cta--ebe41";
export var ctaContainer = "highlighted-content-block-module--ctaContainer--38429";
export var headline = "highlighted-content-block-module--headline--184d3";
export var imageLeft = "highlighted-content-block-module--imageLeft--75e55";
export var imageRight = "highlighted-content-block-module--imageRight--cb3db";
export var imageTextArea = "highlighted-content-block-module--imageTextArea--ba177";
export var infoItems = "highlighted-content-block-module--infoItems--99263";
export var paragraph = "highlighted-content-block-module--paragraph--182ae";
export var picture = "highlighted-content-block-module--picture--991c9";
export var pictureBody = "highlighted-content-block-module--pictureBody--3e133";
export var subheadline = "highlighted-content-block-module--subheadline--4e43a";